import React, {useState, useEffect, useContext} from 'react'
import { UserContext } from '../layouts/UserContext';
import { Link } from 'gatsby'

const PricingES = () => {

const location = useContext(UserContext)

    return (
        <section className="pricing_area sec_pad">
            <div className="container custom_container p0">
                <div className="sec_title text-center">
                    <h2 className="f_p f_size_40 l_height50 f_600">Versión Completa Gratis</h2>
                    <p className="f_400 f_size_16 l_height30 mb_20">Pruebe la versión completa de Workhub sin compromiso <br />o inicie directamente con nuestra económica versión standard.</p>
                </div>

                <div className="tab-content price_content" id="myTabContent">
                    <div className="tab-pane fade show active">
                        <div className="row">
                            <div className="col-lg-2 col-sm-1">
                            </div>
                            <div className="col-lg-4 col-sm-5 mt-2">
                                <div className="price_item">
                                    <img loading="lazy" src="/home2/price-2.png" alt="Software Vector" />
                                    <h3 className="f_p f_size_20 f_600 t_color2 mt_30" style={{marginBottom: 0, fontSize: '18px', lineHeight: '22px', color: '#212322'}}>Gratis</h3>
                                    <p>Pruebe el Programa Completo</p>
                                    <Link to="/pricing/" className="erp_btn_learn">Vea la comparación completa<i className="arrow_right"></i></Link>
                                    <div className="price f_700 f_size_50 t_color2">$0<br /><sub className="f_size_16 f_400">/ mes por usuario</sub></div>
                                    <ul className="list-unstyled p_list">
                                    <li><i className="ti-check"></i>Acceda a todos los componentes</li>
                                        <li><i className="ti-check"></i>Subida limitada de archivos</li>
                                        <li><i className="ti-check"></i>Soporte limitado</li>
                                        <li><i className="ti-close"></i>Sin exportación de datos</li>
                                        <li><i className="ti-close"></i>Sin costos adicionales</li>
                                    </ul>
                                    <Link to="/signup/" className="price_btn btn_hover">Ir Gratis</Link>
                                    <i className="no-cc">Sin tarjeta de crédito</i>
                                </div>
                            </div>

                            <div className="col-lg-4 col-sm-5 mt-2">
                                <div className="price_item">
                                    <img loading="lazy" src="/home2/price-1.png" alt="Trophy Vector" />
                                    <h3 className="f_p f_size_20 f_600 t_color2 mt_30" style={{marginBottom: 0, fontSize: '18px', lineHeight: '22px', color: '#212322'}}>Standard</h3>
                                    <p>Sin Contratos. Sin costos adicionales. Soporte completo.</p>
                                    <Link to="/pricing/" className="erp_btn_learn">Vea la comparación completas<i className="arrow_right"></i></Link>

                                    {location === "US" || location === undefined ? (<><div className="price f_700 f_size_50 t_color2">$3<br /><sub className="f_size_16 f_400"> (USD) / mes por usuario</sub></div></>) : null}

                                    {location === "CA" ? (<><div className="price f_700 f_size_50 t_color2">$4<br /><sub className="f_size_16 f_400"> (CAD) / mo per user</sub></div></>) : null}

                                    {location !== 'US' && location !== 'CA' && location !== undefined ? (<><div className="price f_700 f_size_50 t_color2">$3<br /><sub className="f_size_16 f_400"> (USD) / mo per user</sub></div></>) : null}
                                    
                                    <ul className="list-unstyled p_list">
                                    <li><i className="ti-check"></i>Personalización visual</li>
                                        <li><i className="ti-check"></i>Asignaciones con base a locación</li>
                                        <li><i className="ti-check"></i>Completo soporte por teléfono / Email</li>
                                        <li><i className="ti-check"></i>Notificaciones SMS (USA y Canadá)</li>
                                        <li><i className="ti-check"></i>Importación / Exportación de información</li>
                                        <li><i className="ti-check"></i>1 Mes de prueba gratis</li>
                                    </ul>
                                    <Link to="/signup/" className="price_btn btn_hover">Iniciar</Link>
                                    <i className="no-cc">Sin tarjeta de crédito</i>

                                </div>
                            </div>
                            <div className="col-lg-2 col-sm-1">
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default PricingES
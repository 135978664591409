import React, { Component } from "react"
import Slider from "react-slick"
import { Link } from "gatsby"

export default class FeatureCarouselES extends Component {
  render() {
    var settings = {
      dots: true,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 3,
      initialSlide: 0,
      autoplay: true,
      autoplaySpeed: 300000,
      pauseOnHover: true,
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 882,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 570,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    }
    return (
      <section className="feature_area_two">
        <div className="container custom_container">
          <div style={{ textAlign: "center", padding: "0.5rem" }}>
            <h2 className="f_p f_size_30 l_height50 f_600 mb-20">
            Un completo sistema de componentes para optimizar el flujo de trabajo{" "}
            </h2>
            <p className="f_400 f_size_16 mb-0">
            Workhub lo tiene todo, desde entrenamiento en line gratuito hasta seguimiento de certificados, distribución de políticas,{" "}
              <br className="header_break" /> formularios, boletines, competencias, incidentes y{" "}
              <Link to="/features/" className="erp_btn_learn">
              muchos más componentes.
              </Link>
            </p>
          </div>
          {/* <LoadableFeatureCarousel /> */}

          <div className="carousel-container">
            <Slider {...settings}>
              <div className="carousel_contain">
                <div className="carousel_item">
                  <img
                    className="illustration"
                    src="/app-icons/small/workhub-icon-training.svg"
                    alt="Training Icon"
                  />
                  <h3>Entrenamiento en Línea </h3>
                  <p>
                  Mas de 70 cursos gratuitos de los cuales 
                  más de 30 están en español o usted puede 
                  agregar sus propios cursos personalizados 
                  y su cuestionario.
                  </p>
                  <Link
                    to="/features/safety-training/"
                    className="erp_btn_learn"
                  >
                    Learn More{" "}
                    <img
                      style={{ display: "inline", paddingLeft: ".5rem" }}
                      src="/app-icons/layout-icons/long-arrow-right.svg"
                      alt="Arrow"
                    />
                  </Link>
                </div>
              </div>

              <div className="carousel_contain">
                <div className="carousel_item">
                  <img
                    className="illustration"
                    src="/app-icons/small/workhub-icon-policies.svg"
                    alt="Policies Icon"
                  />
                  <h3>Políticas de Seguridad</h3>
                  <p>
                  Almacene sus políticas con control de 
                  versión y obtenga firma digital de sus 
                  trabajadores post revisión. 
                  </p>
                  <Link to="/features/policies/" className="erp_btn_learn">
                    Learn More{" "}
                    <img
                      style={{ display: "inline", paddingLeft: ".5rem" }}
                      src="/app-icons/layout-icons/long-arrow-right.svg"
                      alt="Arrow"
                    />
                  </Link>
                </div>
              </div>

              <div className="carousel_contain">
                <div className="carousel_item">
                  <img
                    className="illustration"
                    src="/app-icons/small/workhub-icon-procedures.svg"
                    alt="Procedures Icon"
                  />
                  <h3>Procedimientos Operativos</h3>
                  <p>
                  Incorpore sus procedimientos de seguridad y rastree que trabajadores los han revisado. 
                  </p>
                  <Link
                    to="/features/standard-operating-procedures/"
                    className="erp_btn_learn"
                  >
                    Learn More{" "}
                    <img
                      style={{ display: "inline", paddingLeft: ".5rem" }}
                      src="/app-icons/layout-icons/long-arrow-right.svg"
                      alt="Arrow"
                    />
                  </Link>
                </div>
              </div>

              <div className="carousel_contain">
                <div className="carousel_item">
                  <img
                    className="illustration"
                    src="/app-icons/small/workhub-icon-certificates.svg"
                    alt="Certificate Tracking Icon"
                  />
                  <h3>Seguimiento de Certificados</h3>
                  <p>
                  Lleve registro de cualquier certificado de 
                  un trabajador expedido por terceros para 
                  designación profesional. 
                  </p>
                  <Link
                    to="/features/certificate-tracking/"
                    className="erp_btn_learn"
                  >
                    Learn More{" "}
                    <img
                      style={{ display: "inline", paddingLeft: ".5rem" }}
                      src="/app-icons/layout-icons/long-arrow-right.svg"
                      alt="Arrow"
                    />
                  </Link>
                </div>
              </div>

              <div className="carousel_contain">
                <div className="carousel_item">
                  <img
                    className="illustration"
                    src="/app-icons/small/workhub-icon-polls.svg"
                    alt="Polls Icon"
                  />
                  <h3>Sondeos</h3>
                  <p>
                  Tome decisiones democráticas sondeando 
                  las preferencias de sus trabajadores.{" "}
                  </p>
                  <Link to="/features/polls/" className="erp_btn_learn">
                    Learn More{" "}
                    <img
                      style={{ display: "inline", paddingLeft: ".5rem" }}
                      src="/app-icons/layout-icons/long-arrow-right.svg"
                      alt="Arrow"
                    />
                  </Link>
                </div>
              </div>

              <div className="carousel_contain">
                <div className="carousel_item">
                  <img
                    className="illustration"
                    src="/app-icons/small/workhub-icon-suggestions.svg"
                    alt="Suggestions Icon"
                  />
                  <h3>Sugerencias</h3>
                  <p>
                  Permítales a los trabajadores aportarle 
                  ideas que hagan mejorar su lugar de 
                  trabajo.{" "}
                  </p>
                  <Link to="/features/suggestions/" className="erp_btn_learn">
                    Learn More{" "}
                    <img
                      style={{ display: "inline", paddingLeft: ".5rem" }}
                      src="/app-icons/layout-icons/long-arrow-right.svg"
                      alt="Arrow"
                    />
                  </Link>
                </div>
              </div>

              <div className="carousel_contain">
                <div className="carousel_item">
                  <img
                    className="illustration"
                    src="/app-icons/small/workhub-icon-inspections.svg"
                    alt="Inspections Icon"
                  />
                  <h3>Inspecciones</h3>
                  <p>
                  Programe inspecciones requeridas sobre 
                  los activos, documente hallazgos y asigne 
                  elementos de acción sobre los problemas.{" "}
                  </p>
                  <Link to="/features/inspections/" className="erp_btn_learn">
                    Learn More{" "}
                    <img
                      style={{ display: "inline", paddingLeft: ".5rem" }}
                      src="/app-icons/layout-icons/long-arrow-right.svg"
                      alt="Arrow"
                    />
                  </Link>
                </div>
              </div>

              <div className="carousel_contain">
                <div className="carousel_item">
                  <img
                    className="illustration"
                    src="/app-icons/small/workhub-icon-incidents.svg"
                    alt="Incidents Reporting Icon"
                  />
                  <h3>Seguimiento de Incidentes</h3>
                  <p>
                  Realice seguimiento de accidentes, 
                  peligros, cuasi accidentes, y mantenga 
                  todos sus documentos de incidentes en un 
                  solo lugar.{" "}
                  </p>
                  <Link
                    to="/features/incident-reporting/"
                    className="erp_btn_learn"
                  >
                    Learn More{" "}
                    <img
                      style={{ display: "inline", paddingLeft: ".5rem" }}
                      src="/app-icons/layout-icons/long-arrow-right.svg"
                      alt="Arrow"
                    />
                  </Link>
                </div>
              </div>

              <div className="carousel_contain">
                <div className="carousel_item">
                  <img
                    className="illustration"
                    src="/app-icons/small/workhub-icon-competencies.svg"
                    alt="Competencies Icon"
                  />
                  <h3>Competencias</h3>
                  <p>
                  Cerciórese de que sus trabajadores 
                  son competentes completando tareas 
                  de requisitos previos y evaluando el 
                  rendimiento.
                  </p>
                  <Link to="/features/competencies/" className="erp_btn_learn">
                    Learn More{" "}
                    <img
                      style={{ display: "inline", paddingLeft: ".5rem" }}
                      src="/app-icons/layout-icons/long-arrow-right.svg"
                      alt="Arrow"
                    />
                  </Link>
                </div>
              </div>

              <div className="carousel_contain">
                <div className="carousel_item">
                  <img
                    className="illustration"
                    src="/app-icons/small/workhub-icon-bulletins.svg"
                    alt="Bulletins Icon"
                  />
                  <h3>Boletines y Alertas</h3>
                  <p>
                  Mantenga a los trabajadores actualizados 
                  sobre alertas y actualizaciones con 
                  confirmaciones de lectura automáticas para 
                  evaluar el número de lectores.
                  </p>
                  <Link to="/features/bulletins/" className="erp_btn_learn">
                    Learn More{" "}
                    <img
                      style={{ display: "inline", paddingLeft: ".5rem" }}
                      src="/app-icons/layout-icons/long-arrow-right.svg"
                      alt="Arrow"
                    />
                  </Link>
                </div>
              </div>

              <div className="carousel_contain">
                <div className="carousel_item">
                  <img
                    className="illustration"
                    src="/app-icons/small/workhub-icon-whistleblower.svg"
                    alt="Whistleblower Icon"
                  />
                  <h3>Denuncias</h3>
                  <p>Proteja su reputación y la de los 
                    trabajadores permitiéndoles reportar 
                    anónimamente violaciones a las políticas.</p>
                  <Link to="/features/whistleblower/" className="erp_btn_learn">
                    Learn More{" "}
                    <img
                      style={{ display: "inline", paddingLeft: ".5rem" }}
                      src="/app-icons/layout-icons/long-arrow-right.svg"
                      alt="Arrow"
                    />
                  </Link>
                </div>
              </div>

              <div className="carousel_contain">
                <div className="carousel_item">
                  <img
                    className="illustration"
                    src="/app-icons/small/workhub-icon-orientations.svg"
                    alt="Orientations"
                  />
                  <h3>Orientaciones de Contratista</h3>
                  <p>
                  Almacene sus orientaciones con un 
                  conveniente acceso de contratista y rastree 
                  el cumplimiento.
                  </p>
                  <Link
                    to="/features/contractor-orientations/"
                    className="erp_btn_learn"
                  >
                    Learn More{" "}
                    <img
                      style={{ display: "inline", paddingLeft: ".5rem" }}
                      src="/app-icons/layout-icons/long-arrow-right.svg"
                      alt="Arrow"
                    />
                  </Link>
                </div>
              </div>

              <div className="carousel_contain">
                <div className="carousel_item">
                  <img
                    className="illustration"
                    src="/app-icons/small/workhub-icon-surveys.svg"
                    alt="Surveys Icon"
                  />
                  <h3>Encuestas de Percepción</h3>
                  <p>
                  Realice encuestas anónimas a sus 
                  trabajadores para saber como se sienten 
                  con el programa de seguridad.
                  </p>
                  <Link
                    to="/features/surveys/"
                    className="erp_btn_learn"
                  >
                    Learn More{" "}
                    <img
                      style={{ display: "inline", paddingLeft: ".5rem" }}
                      src="/app-icons/layout-icons/long-arrow-right.svg"
                      alt="Arrow"
                    />
                  </Link>
                </div>
              </div>

              <div className="carousel_contain">
                <div className="carousel_item">
                  <img
                    className="illustration"
                    src="/app-icons/small/workhub-icon-safety-meetings.svg"
                    alt="Safety Meetings Icon"
                  />
                  <h3>Reuniones de Seguridad</h3>
                  <p>
                  Programe reuniones de seguridad, cree un 
                  sendero de documentación digital de lo 
                  discutido, y registre la asistencia.                 
                  </p>
                  <Link
                    to="/features/safety-meetings/"
                    className="erp_btn_learn"
                  >
                    Learn More{" "}
                    <img
                      style={{ display: "inline", paddingLeft: ".5rem" }}
                      src="/app-icons/layout-icons/long-arrow-right.svg"
                      alt="Arrow"
                    />
                  </Link>
                </div>
              </div>


              <div className="carousel_contain">
                <div className="carousel_item">
                  <img
                    className="illustration"
                    src="/app-icons/small/workhub-icon-behaviour-observation.svg"
                    alt="Behaviour Observations"
                  />
                  <h3>Observaciones de comportamiento</h3>
                  <p>
                  Evalué el comportamiento de sus 
                  trabajadores para identificar y evaluar 
                  cualquier habito laboral inseguro.
                  </p>
                  <Link
                    to="/features/behavior-observations/"
                    className="erp_btn_learn"
                  >
                    Learn More{" "}
                    <img
                      style={{ display: "inline", paddingLeft: ".5rem" }}
                      src="/app-icons/layout-icons/long-arrow-right.svg"
                      alt="Arrow"
                    />
                  </Link>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </section>
    )
  }
}

import React from 'react'
import Img from 'gatsby-image/withIEPolyfill'
import ReactWOW from 'react-wow'
const TestimonialES = ({testImages}) => {
    const testimonial = testImages.filter(item => item.node.name === "testimonial-default-img")[0];
    return (
        <section className="payment_testimonial_area">
            <div className="container">
                <div className="row payment_testimonial_info flex-row-reverse">
                    <div className="col-lg-7 d-flex align-items-center">
                        <ReactWOW animation="fadeInRight" delay="0.2s">
                            <div className="testimonial_content">
                                <div className="icon">,,</div>
                                <p className="f_p f_size_20">
                                    Esta es una herramienta muy fácil de usar, igual fácil de implementar y usar por los trabajadores con diversos conocimientos tecnológicos,
                                    es fácil de rastrear el entrenamiento lo que hace mi trabajo fácil. Mantener el entrenamiento de mas de 500 trabajadores es un trabajo de
                                    tiempo completo pero Workhub ha simplificado este proceso. <b><i>Es el iPhone de los programas de seguridad, amigable con el usuario y directo, usted se preguntara como pudo haber trabajado por tanto tiempo sin Workhub!</i></b>
                                </p>
                                <div className="author f_600 f_p t_color f_size_20">Kelsea McLaughlin</div>
                                <div className="author_description f_p f_size_15">Administrador de Seguridad / Viking Air</div>
                            </div>
                        </ReactWOW>
                    </div>
                    <div className="col-lg-5">
                        <div className="testimonial_img">
                            <ReactWOW animation="fadeInLeft" delay="0.4s">
                                <Img fixed={testimonial.node.childImageSharp.fixed} />
                            </ReactWOW>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TestimonialES
import React from 'react'
import CountUp from 'react-countup'
import VisibilitySensor from 'react-visibility-sensor'
import ReactWOW from 'react-wow';
const FactsES = () => {

    return (
        <section className="app_fact_area sec_pad">

            <div className="container">
                <ReactWOW animation="fadeInUp" delay="0.3s">
                    <div className="fact_title text-center mb_70">
                        <h2>Hemos ayudado a más de 20.000 organizaciones<br /> a alcanzar sus metas de cumplimiento de seguridad</h2>
                    </div>
                </ReactWOW>
                <div className="app_fact_info">
                    <div className="app_fact_item">
                        <div className="text">
                            <CountUp
                                start={0}
                                end={359868}
                                duration={1}
                                separator=","
                                delay={0}>
                                {({ countUpRef, start}) => (
                                    <VisibilitySensor onChange={start} delayedCall>
                                        <span className="counter one" ref={countUpRef} />    
                                    </VisibilitySensor>
                                )}
                            </CountUp>
                            <p>Usuarios</p>
                        </div>
                    </div>
                    <div className="app_fact_item">
                        <div className="text">
                            <CountUp
                                start={0}
                                end={21045}
                                duration={2}
                                separator=","
                                delay={0}>
                                {({ countUpRef, start}) => (
                                    <VisibilitySensor onChange={start} delayedCall>
                                        <span className="counter two" ref={countUpRef} />    
                                    </VisibilitySensor>
                                )}
                            </CountUp>
                            <p>Empresas Satisfechas</p>
                        </div>
                    </div>
                    <div className="app_fact_item">
                        <div className="text">
                            <CountUp
                                start={0}
                                end={11379947}
                                duration={2}
                                separator=","
                                delay={0}>
                                {({ countUpRef, start}) => (
                                    <VisibilitySensor onChange={start} delayedCall>
                                        <span className="counter three" ref={countUpRef} />    
                                    </VisibilitySensor>
                                )}
                            </CountUp>
                            <p>Exámenes Completados</p>
                        </div>
                    </div>
                    <div className="app_fact_item last">
                        <div className="text">
                            <CountUp
                                start={0}
                                end={2416366}
                                duration={2}
                                separator=","
                                delay={0}>
                                {({ countUpRef, start}) => (
                                    <VisibilitySensor onChange={start} delayedCall>
                                        <span className="counter four" ref={countUpRef} />    
                                    </VisibilitySensor>
                                )}
                            </CountUp>
                            <p>Documentos Registrados</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FactsES
import React from 'react'
import Typed from 'react-typed';

 
const ComponentsTyped = () => {
    let preferredLanguage = 'eng';
    if(typeof localStorage !== 'undefined'){
       preferredLanguage =  localStorage.getItem('preferredLanguage')
    }
    if(preferredLanguage == 'esp'){
        return (
            <Typed
            strings={[
                'Políticas',
                'Competencias',
                'Inspecciones',
                'Formularios',
                'Orientaciones',
                'Procedimientos',
                'Entrenamiento',
                'Certificados',
                'Auditorias de Seguridad',
                'Boletines',
                'Incidentes']}
                typeSpeed={80}
                startDelay={1000}
                backDelay={1000}
                loop >
            </Typed>
        );
    }else{
        return (
            <Typed
            strings={[
                'Policies',
                'Procedures',
                'Training',
                'Certificates',
                'Bulletins',
                'Incidents',
                'Competencies',
                'Safety Audits',
                'Inspections',
                'Forms & Records',
                'Orientations']}
                typeSpeed={80}
                startDelay={1000}
                backDelay={1000}
                loop >
            </Typed>
        );
    }
}
 
export default ComponentsTyped